import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import {
  Box,
  Anchor,
  Button,
  Image,
  Input,
  Label,
  Icon,
  Text,
} from "../../components/elements";
import { LabelField, LabelTextarea } from "../../components/fields";
import { CardLayout, CardHeader } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/productUpload.json";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useQuery } from "../../hooks/useQuery";
import { hideLoader, showLoader } from "../../store/actions/loader";
import fetchAndConvertImages from "../../helpers/convertUrlToImages";
import {
  addBlogAction,
  getAdminBlogDetailAction,
  getBlogCategories,
  updateAdminBlogAction,
} from "../../store/actions/adminBlog";
import LabelTinyEditor from "../../components/fields/LabelTinyEditor";
import { toastr } from "react-redux-toastr";
import ImageInput from "../../components/elements/ImageInput";
import JoditEditor from "jodit-react";

const CreateBlogs = () => {
  const [uploadFile, setUploadFile] = React.useState("choose");
  const [previewSmallImages, setPreviewSmallImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [imageFile, setImageFile] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState();
  const [content, setContent] = useState();
  const productId = useQuery();
  const [isEditEvent, setEditEventStatus] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { adminBlogCategories } = useSelector((state) => ({
    adminBlogCategories: state.adminBlogs.adminBlogCategories,
  }));

  const [blogData, setBlogData] = useState({
    title: "",
    category: "",
    description: "",
    content: "",
  });

  const [metaData,setMetaData] = useState({
      title: "",
      description: "",
  });

  useEffect(() => {
    if (location?.state?.isBlogEdit) {
     
      dispatch(showLoader());
      dispatch(getAdminBlogDetailAction(productId.get("_id"))).then((res) => {
       
        const blogCategory = {
          label: res?.data?.category,
          value: res?.data?.category,
        };
        // Call the function to convert image URLs into FileList object
        fetchAndConvertImages(res?.data?.blogImage).then((fileList) => {
          formik.setFieldValue("blogImage", fileList);
          setImageFile(fileList);
        });
        fetchAndConvertImages(res?.data?.blogImage2).then((fileList) => {
          formik.setFieldValue("blogImage2", fileList);
          setImageFile(fileList);
        });
        setEditEventStatus(true);
         setMetaData({
                    title: res?.data?.metaData?.title,
                    description: res?.data?.metaData?.description,
                  })
        formik.setFieldValue("category", res?.data?.category);
        formik.setFieldValue("title", res?.data?.title);
        formik.setFieldValue("description", res?.data?.description);
        setPreviewImages(res?.data?.blogImage);
        setPreviewSmallImages(res?.data?.blogImage2);
        setSelectedOptions(blogCategory);
        setContent(res?.data?.content);
      });
      dispatch(hideLoader());
    }
  }, [location?.state?.isBlogEdit]);

  useEffect(() => {
    dispatch(getBlogCategories());
  }, []);

  const blogsCategory =
    adminBlogCategories &&
    adminBlogCategories?.dropdownCategories?.map((el) => {
      return { label: el, value: el };
    });

    const handleUploadSmallImage = (e, setFieldValue) => {
      if ([...e.target.files].length > 2) {
        return toastr.light("Maximum 2 image will be uploaded");
      } else {
        const newImageUrls1 = [];
        [...e.target.files].length >= 1 &&
          [...e.target.files].length < 3 &&
          [...e.target.files].forEach((image) =>
            newImageUrls1.push(URL.createObjectURL(image))
          );
        setPreviewSmallImages(newImageUrls1);
        setFieldValue("blogImage2", e.target.files);
      }
    };

  const handleUploadImage = (e, setFieldValue) => {
    if ([...e.target.files].length > 2) {
      return toastr.light("Maximum 2 image will be uploaded");
    } else {
      const newImageUrls = [];
      [...e.target.files].length >= 1 &&
        [...e.target.files].length < 3 &&
        [...e.target.files].forEach((image) =>
          newImageUrls.push(URL.createObjectURL(image))
        );
      setPreviewImages(newImageUrls);
      setFieldValue("blogImage", e.target.files);
    }
  };

  const handleChange = (e, setFieldValue) => {
    setFieldValue(e.target.name, e.target.value);
  };

  const handleSelectCategory = (data, setFieldValue) => {
    setFieldValue("category", data.value);
    setSelectedOptions(data);
  };

  const handleSubmit = () => {
    let formData = new FormData();
    formData.append("title", formik?.values?.title);
    formData.append(
      "category",
      formik?.values?.category === undefined ? "" : formik?.values?.category
    );
    formData.append("description", formik?.values?.description);
    formData.append("metaData[title]", metaData.title);
    formData.append("metaData[description]", metaData.description);
    formData.append("content", content);
    [...formik?.values?.blogImage].forEach((image) => {
      formData.append("blogImage", image);
    });
    [...formik?.values?.blogImage2].forEach((image) => {
      formData.append("blogImage2", image);
    });
    isEditEvent
      ? dispatch(updateAdminBlogAction(productId.get("_id"), formData))
      : dispatch(addBlogAction(formData));
  };

  const formik = useFormik({
    initialValues: blogData,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <PageLayout>
        <form
          onSubmit={formik.handleSubmit}
          encType="multipart/form-data"
          novalidate
        >
          <Row>
            <Col xl={12}>
              <CardLayout>
                <Breadcrumb title={"Create Blogs"}>
                  {/* {data?.breadcrumb.map((item, index) => (
                    <li key={index} className="mc-breadcrumb-item">
                      {item.path ? (
                        <Anchor className="mc-breadcrumb-link" href={item.path}>
                          {item.text}
                        </Anchor>
                      ) : (
                        item.text
                      )}
                    </li>
                  ))} */}
                </Breadcrumb>
              </CardLayout>
            </Col>
            <Col xl={14}>
              <CardLayout>
                <Row>
                  <Col xl={12}>
                    <LabelField
                      type="creatable"
                      name="category"
                      label="Blog Category"
                      options={blogsCategory}
                      onChange={(e) =>
                        handleSelectCategory(e, formik.setFieldValue)
                      }
                      value={selectedOptions}
                      fieldSize="w-100 h-md"
                    />
                  </Col>
                  <Col xl={12}>
                    <LabelField
                      type="text"
                      label="Blog Title"
                      name="title"
                      fieldSize="w-100 h-md"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.title}
                    />
                  </Col>
                  <Col xl={12}>
                    <LabelTextarea
                      label="Blog Description"
                      name="description"
                      fieldSize="w-100 h-text-md"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.description}
                    />
                  </Col>
                   <Col xl={12}>
                                      <LabelField
                                        type="text"
                                        label="Blogs Meta Title"
                                        name="title"
                                        fieldSize="w-100 h-md"
                                        onChange={(e) => setMetaData({...metaData, title: e.target.value})}
                                        value={metaData.title}
                                      />
                                    </Col>
                                    <Col xl={12}>
                                      <LabelField
                                        type="text"
                                        label="Blog Meta Description"
                                        name="description"
                                        fieldSize="w-100 h-md"
                                        onChange={(e) => setMetaData({...metaData, description: e.target.value})}
                                        value={metaData.description}
                                      />
                                    </Col>
                  <Col xl={12}>
                    {/* <RichTextEditor/> */}
                    <JoditEditor
                    label="Blog Content"
                    value={content}
                    fieldSize="w-100 h-text-md"
                    onChange={(e) => setContent(e)}
                    />
                    
                    {/* <LabelTinyEditor
                      label="Blog Content"
                      value={content}
                      fieldSize="w-100 h-text-md"
                      onEditorChange={(e) => setContent(e)}
                    /> */}
                  </Col>
                </Row>
              </CardLayout>
            </Col>
            <Col xl={5}></Col>
            <Col xl={12}>
              <CardLayout>
                <CardHeader title="Blog Small Image(s)" />
               
                <Box className="mc-product-upload-media">
                  {previewImages &&
                    previewImages.map((img, i) => {
                      return (
                        <Box className="mc-product-upload-image">
                          <Image
                            src={img}
                            alt={i}
                            key={i}
                            onChange={handleChange}
                          />
                        </Box>
                      );
                    })}
                  <Box className="mc-product-upload-file">
                    <ImageInput
                      type="file"
                      name="blogImage[]"
                      multiple
                      accept="image/*"
                      id="blog"
                      onChange={(e) =>
                        handleUploadImage(e, formik.setFieldValue)
                      }
                    />
                    <Label htmlFor="blog">
                      <Icon type="collections" />
                      <Text>{uploadFile}</Text>
                    </Label>
                  </Box>
                </Box>
                {/* {isEditEvent ? (
                  <Button
                    type="submit"
                    className="mc-btn w-100 primary mt-5"
                    text="Update"
                    icon="cloud_upload"
                  />
                ) : (
                  <Button
                    type="submit"
                    className="mc-btn w-100 primary mt-5"
                    text="Create Blog"
                    icon="chat"
                  />
                )} */}
              </CardLayout>
            </Col>
            <Col xl={12}>
              <CardLayout>
                <CardHeader title="Blog Large Image(s)" />
                <Box className="mc-product-upload-media">
                  {previewSmallImages &&
                    previewSmallImages.map((img, i) => {
                      return (
                        <Box className="mc-product-upload-image">
                          <Image
                            src={img}
                            alt={i}
                            key={i}
                            onChange={handleChange}
                          />
                        </Box>
                      );
                    })}
                  <Box className="mc-product-upload-file">
                    <ImageInput
                      type="file"
                      name="blogImage2[]"
                      multiple
                      accept="image/*"
                      id="blog2"
                      onChange={(e) =>
                        handleUploadSmallImage(e, formik.setFieldValue)
                      }
                    />
                    <Label htmlFor="blog2">
                      <Icon type="collections" />
                      <Text>{uploadFile}</Text>
                    </Label>
                  </Box>
                </Box>
                {isEditEvent ? (
                  <Button
                    type="submit"
                    className="mc-btn w-100 primary mt-5"
                    text="Update"
                    icon="cloud_upload"
                  />
                ) : (
                  <Button
                    type="submit"
                    className="mc-btn w-100 primary mt-5"
                    text="Create Blog"
                    icon="chat"
                  />
                )}
              </CardLayout>
            </Col>
          </Row>
        </form>
      </PageLayout>
    </>
  );
};

export default CreateBlogs;
