import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import {
  Box,
  Anchor,
  Button,
  Image,
  Input,
  Label,
  Icon,
  Text,
} from "../../components/elements";
import { LabelField, LabelTextarea } from "../../components/fields";
import { CardLayout, CardHeader } from "../../components/cards";
import Breadcrumb from "../../components/Breadcrumb";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/productUpload.json";
import { validationsSchema, variantsValidationsSchema } from "../../utils/formValidations";
import { useFormik } from "formik";
import {
  addProductAction,
  addVariantAction,
  getAdminParentProductsAction,
  getAdminProductDetailAction,
  getAdminVariantProductsAction,
  updateAdminProductAction,
} from "../../store/actions/adminProduct";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { detailKeyOptionList, flavourOptionList, optionList, sizeOptionList } from "../../utils/productConstants";
import { useQuery } from "../../hooks/useQuery";
import { hideLoader, showLoader } from "../../store/actions/loader";
import fetchAndConvertImages from "../../helpers/convertUrlToImages";
import history from "../../utils/history";
import ImageInput from "../../components/elements/ImageInput";

const VariantsUpload = () => {
  const [uploadFile, setUploadFile] = React.useState("image upload");
  const [selectedDetailKey, setSelectedDetailKey] = useState();
  const [previewImages, setPreviewImages] = useState([]);
  const [imageFile, setImageFile] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState();
  const [selectedFlavourOptions, setSelectedFlavourOptions] = useState();
  const [selectedIdOptions, setSelectedIdOptions] = useState();
  const productId = useQuery();
  const [isEditEvent, setEditEventStatus] = useState(false);
  const [RelationshipsId,setRelationshipId] = useState();
  const [vCategory,setVCategory] = useState();
  const [vFlavour,setVFlavour] = useState();
  const dispatch = useDispatch();
  const location = useLocation();
  const [metaData,setMetaData] = useState({
      title: "",
      description: "",
  });
  // const { products, loading, meta } = useSelector((state) => ({
  //   products: state.adminProduct.adminProducts?.products,
  //   loading: state.loader.loading,
  //   status: state.adminProduct.status,
  //   meta: state.adminProduct.adminProducts?.meta,
  //   apiType: state.adminProduct.apiType,
  // }));
  const {productDetails,adminParentProducts} = useSelector(
    (state) => ({productDetails : state.adminProduct.adminProductDetail,
      adminParentProducts : state.adminProduct?.adminParentProducts?.parentProducts})
  );
  const [queuedImagesArray, setQueuedImagesArray] = useState([]);
  const [productData, setProductData] = useState({
    Name: "",
    MRP: "",
    DiscountedPrice: "",
    Stock: "",
    SKUs: "",
    Sizes: [],
    About: "",
    ProductDetails: "",
    DetailImages: [],
    Disclamer: "",
    ManufacturedBy: "",
    MarketedBy: "",
    CountryOfOrigin: "",
  });

  useEffect(()=>{
    dispatch(getAdminParentProductsAction());
  },[])


//   useEffect(() => {
//     if (location?.state?.isEdit) {
//       dispatch(showLoader());
//       dispatch(getAdminProductDetailAction(productId.get("_id"))).then(
//         (res) => {
//           const variant = res?.data?.Variants &&  res?.data?.Variants.map((item) => {
//             return { label: item, value: item };
//           });
//           const size = variant?.map((el) => {
//             return el.value;
//           });
//           // Call the function to convert image URLs into FileList object
//           fetchAndConvertImages(res?.data?.ImagesUrls).then((fileList) => {
//             formik.setFieldValue("ImagesUrls", fileList);
//             setImageFile(fileList);
//           });
//           setEditEventStatus(true); 
//           formik.setFieldValue("Name", res?.data?.Name);
//           formik.setFieldValue("MRP", res?.data?.MRP);
//           formik.setFieldValue("DiscountedPrice", res?.data?.DiscountedPrice);
//           formik.setFieldValue("SKUs", res?.data?.SKUs);
//           formik.setFieldValue("Stock", res?.data?.Stock);
//           formik.setFieldValue("ShortDescription", res?.data?.ShortDescription);
//           formik.setFieldValue("LongDescription", res?.data?.LongDescription);
//           formik.setFieldValue("Category", res?.data?.Category);
//           formik.setFieldValue("Disclaimer", res?.data?.Disclaimer);
//           formik.setFieldValue("Variants", size);
//           setPreviewImages(res?.data?.ImagesUrls);
//           setSelectedOptions(variant);
//         }
//       );
//       dispatch(hideLoader());
//     }
//   }, [location?.state?.isEdit]);
  useEffect(() => {
    if (location?.state?.productId) {
      dispatch(getAdminProductDetailAction(location?.state?.productId)).then((res) => {
          // const parsedMetaData = res?.data?.metaData && JSON.parse(res?.data?.metaData);
          setMetaData({
            title: res?.data?.metaData?.title,
            description: res?.data?.metaData?.description,
          })
        setRelationshipId(res?.data?.RelationshipId)
        setVCategory(res?.data?.Category)
        setSelectedDetailKey(res?.data?.DetailKey)
        setVFlavour(res?.data?.FlavourVariant)
      });
      
    }
  }, [location?.state?.productId]);

  const handleUploadImage = (e, setFieldValue) => {
    const img = productDetails?.allImages.map((el) => el);
    if ([...e.target.files].length < 1) return;
    const newImageUrls = [];
    [...e.target.files].forEach((image) =>
      newImageUrls.push(URL.createObjectURL(image))
    );
    setPreviewImages(newImageUrls);
    setFieldValue("ImagesUrls", e.target.files);
  };


  const onImageChange = (e,setFieldValue) => {
    const files = e.target.files;
    const updatedQueue = [...queuedImagesArray];

    for (let i = 0; i < files.length; i++) {
      updatedQueue.push(files[i]);
    }
    setQueuedImagesArray(updatedQueue);
    setFieldValue("ImagesUrls", updatedQueue);
    e.target.form.reset();
  }

  const deleteQueuedImage = (index) => {
    const updatedQueue = [...queuedImagesArray];
    updatedQueue.splice(index, 1);
    setQueuedImagesArray(updatedQueue);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const updatedQueue = [...queuedImagesArray];

    for (let i = 0; i < files.length; i++) {
      if (!files[i].type.match('image')) continue;
      if (updatedQueue.every((image) => image.name !== files[i].name)) {
        updatedQueue.push(files[i]);
      }
    }

    setQueuedImagesArray(updatedQueue);
  };

  const handleChange = (e, setFieldValue) => {
    const input = e.target.value;

    const cleanedValue = input.replace(/\D/g, '');
    
    // Limit to 10 digits
    const limitedValue = cleanedValue.slice(0, 10);
    setFieldValue(e.target.name, e.target.name === "MRP" || e.target.name === "DiscountedPrice" || e.target.name === "Stock" ? limitedValue : e.target.value);
  };

  const handleSelectSize = (data, setFieldValue) => {
    // const productSize = data.map((el) => {
    //   return el.value;
    // });
    setFieldValue("SizeVariant", data.value);
    setSelectedOptions(data);
  };

  const handleSelectFlavour = (data, setFieldValue) => {
    // const productSize = data.map((el) => {
    //   return el.value;
    // });
    setFieldValue("FlavourVariant", data.value);
    setSelectedFlavourOptions(data);
  };


  const handleSubmit = () => {
    let formData = new FormData();
    formData.append("Name", formik?.values?.Name);
    formData.append("MRP", formik?.values?.MRP);
    formData.append("DiscountedPrice", formik?.values?.DiscountedPrice);
    formData.append("SKUs", formik?.values?.SKUs);
    formData.append("Stock", formik?.values?.Stock);
    formData.append("DetailKey", selectedDetailKey);
    // formData.append("ShortDescription", formik?.values?.ShortDescription);
    // formData.append("LongDescription", formik?.values?.LongDescription);
    // formData.append("Disclaimer", formik?.values?.Disclaimer);
    formData.append("Category",vCategory);
    formData.append("SizeVariant", formik?.values?.SizeVariant === undefined ? "" : formik?.values?.SizeVariant );
    // formData.append("FlavourVariant",formik?.values?.FlavourVariant === undefined ? "" : formik?.values?.FlavourVariant);
    formData.append("FlavourVariant",vFlavour);
    formData.append("RelationshipId", RelationshipsId);
    formData.append("metaData[title]", metaData.title);
formData.append("metaData[description]", metaData.description);
    [...formik.values.ImagesUrls].forEach((image) => {
      formData.append("ImagesUrls", image);
    });
    isEditEvent
      ? dispatch(updateAdminProductAction(productId.get("_id"), formData))
      : dispatch(addVariantAction(formData))
      history.push({ pathname : `/product-view`, search:`?_id=${location?.state?.productId}` , state: location?.state?.productId });;
  };

  const formik = useFormik({
    initialValues: { productData },
    validationSchema: variantsValidationsSchema,
    onSubmit: handleSubmit,
  });

  const handleSelectDetailKey = (data, setFieldValue) => {
    // const productSize = data.map((el) => {
    //   return el.value;
    // });
    setFieldValue("DetailKey", data.value);
    setSelectedDetailKey(data);
  };
//   const parentProductList = adminParentProducts &&  adminParentProducts.map((el) => { return {label: el.Name, value: el.Name ,id: el._id}})
//   const parentProductId = adminParentProducts && adminParentProducts.map((el) => { return {label: el._id, value: el._id}})
//   const handleSelectParentProductId = (data, setFieldValue) => {
//     // const productSize = data.map((el) => {
//     //   return el.value;
//     // });
//     setFieldValue("RelationshipId", data.id);
//     setSelectedIdOptions(data);
//   };
  return (
    <>
      <PageLayout>
        <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
          <Row>
            <Col xl={12}>
              {/* <CardLayout>
                <Breadcrumb title={data?.variantsTitle}>
                  {data?.breadcrumb.map((item, index) => (
                    <li key={index} className="mc-breadcrumb-item">
                      {item.path ? (
                        <Anchor className="mc-breadcrumb-link" href={item.path}>
                          {item.text}
                        </Anchor>
                      ) : (
                        item.text
                      )}
                    </li>
                  ))}
                </Breadcrumb>
              </CardLayout> */}
            </Col>
            <Col xl={14}>
              <CardLayout>
              <CardHeader
                  title="ADD PRODUCT VARIANTS"
                  // dotsMenu={data?.dotsMenu}
                />
                <Row>
                  {/* <Col xl={12}>
<input {...formik.getFieldProps('Name')}></input>
</Col> */}
                  <Col xl={12}>
                    <LabelField
                      type="text"
                      label="Variant Name"
                      name="Name"
                      fieldSize="w-100 h-md"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.Name}
                    />
                  </Col>
                  {formik.touched.Name && formik.errors.Name ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      *{formik.errors.Name}
                    </span>
                  ) : null}
                  {/* <Col xl={12}><LabelTextarea label="description" fieldSize="w-100 h-text-md" {...formik.getFieldProps('Name')} /></Col> */}
                  {/* <Col xl={6}><LabelField label="category" option={['mans', 'womans', 'accessory']} {...formik.getFieldProps('Name')} fieldSize="w-100 h-md" /></Col>
<Col xl={6}><LabelField label="brand" option={['richman', 'lubana', 'ecstasy']} {...formik.getFieldProps('Name')} fieldSize="w-100 h-md" /></Col> */}
                  <Col xl={6}>
                    <LabelField
                      type="text"
                      name="MRP"
                      label="Regular Price(₹)"
                      fieldSize="w-100 h-md"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.MRP}
                    />
                  </Col>
                  {formik.touched.MRP && formik.errors.MRP ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      *{formik.errors.MRP}
                    </span>
                  ) : null}
                  <Col xl={6}>
                    <LabelField
                      type="text"
                      inputmode="numeric"
                      name="DiscountedPrice"
                      label="Sale Price(₹)"
                      fieldSize="w-100 h-md"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.DiscountedPrice}
                    />
                  </Col>
                  {formik.touched.DiscountedPrice &&
                    formik.errors.DiscountedPrice ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      *{formik.errors.DiscountedPrice}
                    </span>
                  ) : null}
                  {/* <Col xl={6}><LabelField type="text" label="shipping fee" fieldSize="w-100 h-md" {...formik.getFieldProps('Name')} /></Col>
<Col xl={6}><LabelField type="text" label="tax rate" fieldSize="w-100 h-md" {...formik.getFieldProps('Name')} /></Col> */}
                  <Col xl={6}>
                    <LabelField
                      type="text"
                      name="SKUs"
                      label="SKUs"
                      fieldSize="w-100 h-md"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.SKUs}
                    />
                  </Col>
                  {formik.touched.SKUs && formik.errors.SKUs ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      *{formik.errors.SKUs}
                    </span>
                  ) : null}
                  <Col xl={6}>
                    <LabelField
                      type="text"
                      name="Stock"
                      label="Inventory/Stock"
                      fieldSize="w-100 h-md"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.Stock}
                    />
                  </Col>
                  {formik.touched.Stock && formik.errors.Stock ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      *{formik.errors.Stock}
                    </span>
                  ) : null}
                  {/* <Col xl={6}>
                    <LabelField
                      type="text"
                      name="ManufacturedBy"
                      label="Manufactured By"
                      fieldSize="w-100 h-md"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.ManufacturedBy}
                    />
                  </Col>
                  {formik.touched.ManufacturedBy &&
                  formik.errors.ManufacturedBy ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      *{formik.errors.ManufacturedBy}
                    </span>
                  ) : null}
                  <Col xl={6}>
                    <LabelField
                      type="text"
                      name="MarketedBy"
                      label="Marketed By"
                      fieldSize="w-100 h-md"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.MarketedBy}
                    />
                  </Col> 
                  {formik.touched.MarketedBy && formik.errors.MarketedBy ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      *{formik.errors.MarketedBy}
                    </span>
                  ) : null}*/}
                  {/* <Col xl={6}>
                    <LabelField
                      type="text"
                      name="CountryOfOrigin"
                      label="Country Of Origin"
                      onChange={(e) => handleChange(e, formik.setFieldValue)}
                      value={formik.values.CountryOfOrigin}
                      fieldSize="w-100 h-md"
                    />
                  </Col> 
                  {formik.touched.CountryOfOrigin &&
                  formik.errors.CountryOfOrigin ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      *{formik.errors.CountryOfOrigin}
                    </span>
                  ) : null}*/}
                  <Col xl={12}>
                    <LabelField
                      disabled
                      type="text"
                      name="Category"
                      label="Category"
                      value={vCategory}
                      fieldSize="w-100 h-md"
                    />
                  </Col>
                  {formik.touched.Category && formik.errors.Category ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      *{formik.errors.Category}
                    </span>
                  ) : null}
                  <Col xl={12}>
                    {/* <Box className="mc-product-upload-organize">
                      <LabelField
                        type="text"
                        label="add size"
                        fieldSize="w-100 h-sm"
                      />
                      <Button className="mc-btn primary">add</Button>
                    </Box> */}
                    <LabelField
                      type="text"
                      name="DetailKey"
                      label="Product Type"
                      // options={detailKeyOptionList}
                      // onChange={(e) => handleSelectDetailKey(e, formik.setFieldValue)}
                      value={selectedDetailKey}
                      fieldSize="w-100 h-md"
                      disabled
                    />
                  </Col>
                    <Col xl={12}>
                    {/* <Box className="mc-product-upload-organize">
                      <LabelField
                        type="text"
                        label="add size"
                        fieldSize="w-100 h-sm"
                      />
                      <Button className="mc-btn primary">add</Button>
                    </Box> */}
                    <LabelField
                      type="creatable"
                      name="SizeVariant"
                      label="Size"
                      options={sizeOptionList}
                      onChange={(e) => handleSelectSize(e, formik.setFieldValue)}
                      value={selectedOptions}
                      fieldSize="w-100 h-md"
                    />
                  </Col>
                    <Col xl={12}>
                    {/* <Box className="mc-product-upload-organize">
                      <LabelField
                        type="text"
                        label="add size"
                        fieldSize="w-100 h-sm"
                      />
                      <Button className="mc-btn primary">add</Button>
                    </Box> */}
                    <LabelField
                    disabled
                      type="text"
                      name="FlavourVariant"
                      label="Flavour"
                      // options={flavourOptionList}
                      // onChange={(e) => handleSelectFlavour(e, formik.setFieldValue)}
                      value={vFlavour}
                      fieldSize="w-100 h-md"
                    />
                  </Col>
                   <Col xl={12}>
                                      <LabelField
                                        type="text"
                                        label="Meta Title"
                                        name="title"
                                        fieldSize="w-100 h-md"
                                        onChange={(e) => setMetaData({...metaData, title: e.target.value})}
                                        value={metaData.title}
                                      />
                                    </Col>
                                    <Col xl={12}>
                                      <LabelField
                                        type="text"
                                        label="Meta Description"
                                        name="Description"
                                        fieldSize="w-100 h-md"
                                        onChange={(e) => setMetaData({...metaData, description: e.target.value})}
                                        value={metaData.description}
                                      />
                                    </Col>
                    {/* <Col xl={12}>
                    <Box className="mc-product-upload-organize">
                      <LabelField
                        type="text"
                        label="add size"
                        fieldSize="w-100 h-sm"
                      />
                      <Button className="mc-btn primary">add</Button>
                    </Box>
                    <LabelField
                      type="select"
                      name="RelationshipId"
                      label="Parent Product"
                      options={parentProductList}
                      onChange={(e) => handleSelectParentProductId(e, formik.setFieldValue)}
                      value={selectedIdOptions}
                      fieldSize="w-100 h-md"
                    />
                  </Col> */}
                </Row>
              </CardLayout>
            </Col>
            <Col xl={5}>
              {/* <CardLayout className="mb-4">
                <CardHeader title="organization" dotsMenu={data?.dotsMenu} />
                <Row>
                  <Col xl={12}>
                    <Box className="mc-product-upload-organize mb-4">
                      <LabelField
                        type="text"
                        label="add category"
                        fieldSize="w-100 h-sm"
                      />
                      <Button className="mc-btn primary">add</Button>
                    </Box>
                    <Box className="mc-product-upload-organize mb-4">
                      <LabelField
                        type="text"
                        label="add brand"
                        fieldSize="w-100 h-sm"
                      />
                      <Button className="mc-btn primary">add</Button>
                    </Box>
                    <Box className="mc-product-upload-organize mb-4">
                      <LabelField
                        type="text"
                        label="add color"
                        fieldSize="w-100 h-sm"
                      />
                      <Button className="mc-btn primary">add</Button>
                    </Box>
                    <Box className="mc-product-upload-organize">
                      <LabelField
                        type="text"
                        label="add size"
                        fieldSize="w-100 h-sm"
                      />
                      <Button className="mc-btn primary">add</Button>
                    </Box>
                  </Col>
                </Row>
              </CardLayout> */}
              {/* <CardLayout>
                <CardHeader title="specification" dotsMenu={data?.dotsMenu} />
                <Row>
                  <Col xl={6}>
                    <LabelField
                      label="size"
                      option={["sm", "md", "lg", "xl", "xxl"]}
                      fieldSize="w-100 h-multiple"
                      multiple
                    />
                  </Col>
                  <Col xl={6}>
                    <LabelField
                      label="color"
                      option={["red", "green", "blue", "pink", "black"]}
                      fieldSize="w-100 h-multiple"
                      multiple
                    />
                  </Col>
                   <Col xl={6}><LabelField type="text" label="stock" fieldSize="w-100 h-md" /></Col>
<Col xl={6}><LabelField type="text" label="weight" fieldSize="w-100 h-md" /></Col> 
                </Row>
              </CardLayout> */}
            </Col>
            <Col xl={12}>
              <CardLayout>
                <CardHeader title="Product Variant Images" />
                <Box className="mc-product-upload-media">
                {queuedImagesArray &&
                    queuedImagesArray.map((img, i) => {
                      return (
                        <Box key={i}  className="mc-product-upload-image">
                          <Image
                            src={!location?.state?.isEdit ? URL.createObjectURL(img) : img}
                          />
                          <span style={{color: "red" ,fontSize: '24px', cursor: 'pointer'}} onClick={() => deleteQueuedImage(i)}>&times;</span>
                        </Box>
                      );
                    })}
                  <Box className="mc-product-upload-file"
                  onDrop={(e) => handleDrop(e)}
                  onDragOver={(e) => e.preventDefault()}>
                    <ImageInput
                      type="file"
                      name="ImageUrls[]"
                      multiple
                      accept="image/*"
                      id="product"
                      onChange={(e) =>
                        onImageChange(e,formik.setFieldValue)
                      }
                    />
                    <Label htmlFor="product">
                      <Icon type="collections" />
                      <Text>{uploadFile}</Text>
                    </Label>
                  </Box>
                </Box>
                {isEditEvent ? (
                  <Button
                    type="submit"
                    className="mc-btn w-100 primary mt-5"
                    text="Update"
                    icon="cloud_upload"
                  />
                ) : (
                  <Button
                    type="submit"
                    className="mc-btn w-100 primary mt-5"
                    text="Add Variant"
                    icon="cloud_upload"
                  />
                )}
              </CardLayout>
            </Col>
          </Row>
        </form>
      </PageLayout>
    </>
  );
};

export default VariantsUpload;
